import styled from "styled-components"

export const MainContainer = styled.div`
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
animation: flip-horizontal-bottom 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    width: 90%;
    height: 5rem;
    background-color: #ffffff;
    margin: 10px auto 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem 0;
    flex-wrap: wrap;
    p{
        margin: 0;
        font-size: 14px;
        :first-child{
            width: 100%;
            text-align: center;
        }
    }
    button{
        background-color: #25a244;
    }
    @media(max-width: 600px){
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
    }
`
export const ContentContainer = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    :last-child{
        :hover{
            cursor: pointer;
        }
    }
    @media(max-width: 600px){
       width: 20%;
       height: 2rem;
       margin: 0.1rem;
    }
`