import {MainContainer, ContentContainer} from "./style"
// import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
import { AiOutlineCloudDownload } from 'react-icons/ai';

const BoletoContainer = ({sac, num, valor, date, link}) => {
  
    const configPessoa = {
        method: "GET",
        headers: {
          "authorization-token":
            "22b547b7fa610be5374e5aeb3c2edcd8c0fc613015bb286f4993e49e6829b429426ed6da9b420d0a5ec15c17c6659a32368b953b512dbdea66b17ced57b8a736bee7ad2914081fb798631eb08352039feb5d31ba24fb400a8936f35ba958ab84d2c89c5de759de7fbbe6a9d557e4f91f737e0aaa605ec62ac7387f8694b1fad2",
          "Content-Type": "application/json",
          user: "ramon@inforperson.com.br",
          app: "AppPersonCloud",
        },
      };

    const ViewBoleto = () => {
    fetch(`https://cors.dc3cloud.com.br/${link}`, configPessoa)
    .then(function(response) {
      return response.text()
  }).then(function(html) {
    var parser = new DOMParser();
    let newWindow = window.open()
    var doc = parser.parseFromString(html, "text/html");
    var docArticle = doc.querySelector('html').innerHTML;
    newWindow.document.write(docArticle)
})}

  let data = date.slice(0,10)
  let newdate = data.split("-")
  let finaldate = newdate.reverse().join("-")

return <>
        <MainContainer>
          
          <ContentContainer>
              <p>{num}</p>
          </ContentContainer>
          
          <ContentContainer>
            <p>{valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
          </ContentContainer>
          
          <ContentContainer>
            <p>{finaldate}</p>
          </ContentContainer>
          
          <ContentContainer>       
            <AiOutlineCloudDownload size="1.4rem" onClick={ViewBoleto}/>
            
            </ContentContainer>
        </MainContainer>
    </>
}

export default BoletoContainer