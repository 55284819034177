import { MainContainer, 
Descriptions,
NewInput,
ImageContainer,
LogoImg,
MyImage,
HeaderContainer,
MyimageContainer,
BoletosContainer, 
NewForm,
Messages,
Saudacoes,
HeaderColum,
// NewDescriptions,
Footer,
ContentContainer,
ContentFooter } from "./style.js";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import  { useState } from 'react';
import LoadingGif from "./imagens/LoadingGif.gif"
import React, { useCallback } from "react";
import img from "./imagens/img00.svg"
import logo from "./imagens/logo.png"
import BoletoContainer from "./components/boletoContainer"
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterCircle } from 'react-icons/ai';

function App() {
  let ano = new Date()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [erro, setErro] = useState("")

  const useYupValidationResolver = validationSchema =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });

        return {
          values,
          errors: {}
        };
      } catch (errors) {
        setErro('Deve conter entre 11 e 14 dígitos númericos') 
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )          
        };       
      }
    },
    [validationSchema]
  );

  const schema = yup.object().shape({
    cnpj: yup.string('Deve conter entre 11 e 14 dígitos númericos')
    .required('Deve conter entre 11 e 14 dígitos númericos')
    .matches(/^[0-9]+$/, "Apenas Numeros")
    .min(11, 'Deve conter entre 11 e 14 dígitos númericos')
  });

  const { register, handleSubmit } = useForm({
    resolver: useYupValidationResolver(schema),
  });
  const MyFunc = (data) => {
  setLoading(true)
  setErro("")
  fetch('https://cors.dc3cloud.com.br/https://auth.dc3cloud.com.br/boleto/busca',{
    method: "POST",
    headers:{
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify({
      cnpj: data.cnpj
    })
  })
  .then(function(response) {
      if(response.status === 404){
        setLoading(false)
        alert("Boleto não encontrado.")
      }
      return response.json()
  })
  .then(function(data) {
    setData(data)
    setLoading(false)
  
  })
  .catch(function(err) {  
      console.log('Failed to fetch page: ', err);  
  });
}
  return (
    <>
      <MainContainer>
        <ImageContainer>
          <HeaderContainer> 
            <a href="https://personcloud.com.br">
            <LogoImg src={logo}></LogoImg>
            </a>
          </HeaderContainer>
          <MyimageContainer>
            <MyImage src={img}></MyImage>
          </MyimageContainer>
        </ImageContainer>
      
        <ContentContainer>
          <BoletosContainer>
            <NewForm onSubmit={handleSubmit(MyFunc)}>
              <Descriptions>Baixar Boleto</Descriptions>
              <NewInput
                required
                label="CPF/CNPJ"
                name="CNPJ"
                type="text"
                {...register("cnpj")}
              />            

                <Button 
                  type="submit"
                  variant="contained"
                  color="primary">
                  {!loading ? "Buscar Boleto" :  <img alt="" src={LoadingGif} />}  
                </Button>
            </NewForm> 
            
            <Messages>
              {erro !== "" ?  <p>{erro}</p> : "" }
              <br />
              <p>Insira o seu CPF ou CNPJ cadastrado em nosso sistema e aguarde até 10 segundos para localizarmos o seu boleto em aberto.</p>
              <br />
            </Messages>
            
            <Saudacoes>
              {data.length > 0 && <p>Olá, {data[0]["Sacado"]}.</p>}
            </Saudacoes>
            
            {data.length > 0 ? 
              <HeaderColum>
                <p>Nº Doc</p>
                <p>Valor</p>
                <p>Vencimento</p>
                <p>Baixar</p>
              </HeaderColum>
            : ""}            
              
            {data?.map(({Sacado, NumDoc, Valor, Data, link}, key) => (
            <BoletoContainer num={NumDoc} valor={Valor} date={Data} link={link} /> ))
              }
          </BoletosContainer>
          
          <Footer>
            <ContentFooter>
              <a target="_blank" href="https://www.facebook.com/personclouderp" rel="noreferrer">
                <AiFillFacebook size="1rem" />
                <p>Facebook</p>
              </a>
              <a target="_blank" href="https://www.instagram.com/personclouderp" rel="noreferrer">
                <AiFillInstagram size="1rem" />
                <p>Instagran</p>
              </a>
            
              <a target="_blank" href="https://www.linkedin.com/company/personclouderp" rel="noreferrer">
                <AiFillLinkedin size="1rem" />
                <p>Linkedin</p>
              </a>

              <a target="_blank" href="https://twitter.com/personclouderp" rel="noreferrer">
                <AiFillTwitterCircle size="1rem" />
                <p>Twitter</p>
              </a>
            </ContentFooter>
            <br></br>
            {`©️ ${ano.getFullYear()} ERP PERSON CLOUD.`} <br /> {`Todos os direitos reservados a`} <br /> <a target="_blank" href="https://inforperson.com.br" rel="noreferrer"> {`INFORPERSON CONSULTORIA`}</a>
          </Footer>
        </ContentContainer>
      </MainContainer>
    </>
  );
}
export default App;