import styled from "styled-components";
import TextField from '@material-ui/core/TextField';

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  width: 30%;
  height: 100vh;
  background-color: #f4f4fe;

  @media (max-width: 600px){
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;

  button{
    background-color: #25a244 !important;
    height: 2rem !important;
    width: 9rem !important;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;

    position: absolute;
    right: 4rem;
    top: 1rem;
    z-index: 999;

    a{
      text-decoration: none !important;
      color: white;
      text-transform: capitalize;
      font-size: 14px;
    }
  }
`;

export const MyimageContainer = styled.div`
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
  width: 100%;
  height: 10vh;
  margin-top: 5rem;
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  animation: 1s ease-out 0s 1 slideInFromLeft;

  `;
export const LogoImg = styled.img`
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
animation: 1s ease-out 0s 1 slideInFromLeft;
  width: 227px;
  height: 66;
  margin: 1rem;
`;

export const MyImage = styled.img`
  width: 80%;
  height: auto;
  margin: 0 0 0 2rem;
`

export const NewInput = styled(TextField)`
width: 15rem;

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  transition: background-color #f7f7f7 5000s ease-in-out 0s;
}

`
export const DefaultForm = styled.form`

@keyframes scaleupcenter {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
  animation: 1s ease-out 0s 1 scaleupcenter;
  width: 65%;

  background-color: #ffffff;
  display: flex;
  align-items: center;

  flex-direction: column;

  text-align: center;

  button{
    width: 10rem;
    margin: 1rem;
    height: 2rem;
    animation: 1s ease-out 0s 1 scale-up-center;
  }
  img{
    width: 1.8rem;
  }
  p{
    max-width: 20rem;
    font-weight: 600;
    margin: 10px;
    animation: 1s ease-out 0s 1 scale-up-center;
  }
`;

export const Descriptions = styled.h3`
  font: 15px;
  color: black;
`;

// Nova Tabela de boletos EM PRODUCAO

export const ContentContainer = styled.div`
  width: 70%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media(max-width: 600px){
  width: 100%;
}
`

export const BoletosContainer = styled.div`
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
  width: 0;
}
  @media(max-width: 350px){
  
  font-size: 11px;
    height: 86vh;
}

`

export const NewForm = styled.form`
@keyframes scaleupcenter {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
  animation: 1s ease-out 0s 1 scaleupcenter;

width: 90%;
height: 10rem;
background-color: #ffffff;
margin: 2.5rem auto 0 auto ;

display: flex;
align-items: center;
justify-content: space-around;
flex-direction: column;

text-align: center;

button{
  width: 10rem;
  margin: 1rem;
  height: 2rem;
  background-color: #25a244 !important;
  border-radius: 20px !important;
  text-transform: capitalize;
}
img{
  width: 1.8rem;
}
@media(max-width: 600px){
  width: 100%;
  margin: 1.5rem auto 0 auto ;
}
@media(max-width: 350px){
  
  font-size: 11px;
    height: 9rem;
    margin: 0;
}
`;

export const Messages = styled.div`
@keyframes scaleupcenter {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
animation: 1s ease-out 0s 1 scaleupcenter;
width: 80%;
height: 5rem;
background-color: #ffffff;
margin: 0 auto;

p{
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
@media(max-width: 600px){
  height: 7rem;
}
@media(max-width: 350px){
  p{
  font-size: 11px;
  }
}
`

export const Saudacoes = styled.div`
@keyframes scaleupcenter {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
animation: 1s ease-out 0s 1 scaleupcenter;
width: 80%;
height: 10rem;
background-color: #ffffff;
margin: 0 auto;

p{
  text-align: center;
  font-size: 14px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}
@media(max-width: 350px){
  p{
  font-size: 11px;
  }
}
`

export const Footer = styled.div`
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

animation: slide-top 1s ease-in backwards;

  width: 100%;
  height: 30vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  text-align: center;
  p{
    margin: 0;
    font-weight: 700;    
  }
  a{
    text-decoration: none;
    color: black
  }
  @media(max-width: 700px){
  width: 100%;
  height: 13vh;
  text-align: center;
  font-size: 12px;
}
@media(max-width: 350px){
    p{
      font-size: 10px;
    }
}
`

export const ContentFooter = styled.div`
  text-decoration: none;
  width: 60%;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  text-align: center;
`

export const HeaderColum = styled.div`
  width: 90%;
  height: 2.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto 0 auto;
  p{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }
`